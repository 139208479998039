import { Role } from '../routes/roles'
let userReducers: any

if (localStorage?.getItem('persist:root') !== null) {
  const userInfoState = JSON?.parse(undefined || (localStorage?.getItem('persist:root') as string))
  userReducers = JSON?.parse(undefined || userInfoState?.userReducers)
} else {
  userReducers = undefined
}

export const checkProdSite = (isProtected: boolean, role: string) => {
  if (!userReducers) {
    return 'developerPortal'
  }
  if (userReducers?.userInfo?.role?.name === Role.Developer) {
    if (isProtected && role === Role.Admin) {
      return 'adminPortal'
    } else {
      return 'developerPortal'
    }
  } else if (userReducers?.userInfo?.role?.name === Role.Admin) {
    if (isProtected && role === Role.Developer) {
      return 'developerPortal'
    } else {
      return 'adminPortal'
    }
  }
}


