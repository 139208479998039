import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import iconArrowLeft from '../../../../assets/images/left-arrow.svg'
import iconArrowRight from '../../../../assets/images/right-arrow.svg'
import UserInformation from './components/userInformation'
import { IUserState } from './interfaces'
import UserPermission from './components/userPermission'
import { useLazyQuery } from '@apollo/client'
import { GET_USER_INFORMATION } from '../../../../queries/adminPortal'
import { MAIN_MODULE, SUB_MODULE } from '../../../../constants/common'

const UserDetail = () => {
  const goBackURL = '/admin-portal'
  const location = useLocation()
  const navigate = useNavigate()
  const userState = location.state as IUserState

  const [, { refetch: refetchGetUserInformation }] = useLazyQuery(GET_USER_INFORMATION, {
    variables: {
      userId: userState.user.userId,
    },
  })

  const fullName = `${userState.user.firstName} ${userState.user.lastName}`

  return (
    <div className="px-20 md:px-10">
      <div className="flex justify-end pt-4 pb-1 text-body2 text-neutral-5">
        <span>Admin portal</span>
        <img src={iconArrowLeft} alt="Icon row" width={20} height={20} className="mx-2" />
        <span>Users management</span>
        <img src={iconArrowLeft} alt="Icon row" width={20} height={20} className="mx-2" />
        <span className="text-primary-shade2">User detail</span>
      </div>
      <div className="flex items-center py-3">
        <button
          className="p-2 rounded-full bg-neutral-7"
          onClick={() =>
            navigate(goBackURL, {
              state: {
                mainModule: MAIN_MODULE.ADMIN_PORTAL,
                subModule: SUB_MODULE.USER_MANAGEMENT,
              },
            })
          }
        >
          <img src={iconArrowRight} alt="Icon arrow" />
        </button>
        <div className="flex">
          <span className="pl-8 font-semibold text-neutral-1 text-headline4">{fullName}</span>
        </div>
      </div>
      <div className="px-0 py-5 md:px-10">
        <p className="text-headline5A text-neutral-1 pb-2">User information</p>
        <hr className="border-t-neutral-5" />
        <UserInformation userState={userState} />
        <hr className="my-10 border-t-neutral-6" />
        <p className="text-headline5A text-neutral-1 pb-2">User Permission</p>
        <hr className="border-t-neutral-5" />
        <UserPermission
          userState={userState}
          refetchGetUserInformation={refetchGetUserInformation}
        />
      </div>
    </div>
  )
}

export default UserDetail
