import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import iconArrowLeft from '../../assets/images/left-arrow.svg'
import iconArrowRight from '../../assets/images/right-arrow.svg'
import {
  clearCountSearchPrincipalAccount,
  clearPrincipalAccountDetail,
  clearPrincipalAccountInfoDetail,
  clearSearchPrincipal,
  clearTakeItemSearchPrincipalAccount,
  clearValueSearchPrincipalAddress,
  clearValueSearchPrincipalName,
  closePrincipalAccountDetail,
} from '../../redux/actions/adminPortal'
import { RootState } from '../../store'
import { useWindowSize } from '../../helpers/useWindowSize'
import ShadowTab from './shadowTab'
import { screens } from '../../constants/screens'

export type TabsType = {
  label: string
  index: number
  Component: React.FC<{ index: number }>
  roles?: string[]
  kinds?: Array<string | null>
  name: string
}[]

export type TabsProps = {
  tabs: TabsType
  selectedTab: number
  onClick: (index: number) => void
  orientation?: 'horizontal' | 'vertical'
  className?: string
  pageName: string
  pageNameParent?: string
  carrier?: any
  goBackURL?: string
  needResponsive?: boolean
}

/**
 * Avalible Props
 * @param className string
 * @param tab Array of object
 * @param selectedTab select current tab
 * @param onClick Function to set the active tab
 * @param orientation Tab orientation Vertical | Horizontal
 * @param pageName Name of page parent
 * @param carrier Name of  carrier
 * @param goBack back to this page
 */

/** */
const Tabs: FC<TabsProps> = ({
  className = '',
  tabs = [],
  selectedTab = 0,
  onClick,
  orientation = 'horizontal',
  pageName = '',
  carrier = '',
  pageNameParent = '',
  goBackURL = '',
  needResponsive = false,
}) => {
  const Panel = tabs && tabs.find((tab) => tab.index === selectedTab)
  const activeTabClass = 'bg-primary-shade4 text-primary-shade1'
  const inActiveTabClass = 'text-neutral-4 bg-transparent'

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const windowSize = useWindowSize()

  const isOpenPrincipalAccountDetail = useSelector(
    (state: RootState) => state?.adminPortalReducer?.isOpenPrincipalAccountDetail,
  )

  const principalAccountName = useSelector(
    (state: RootState) => state?.adminPortalReducer?.principalAccountName,
  )

  // keep this, because we will use it later
  // const handleOnWheel = (e: any) => {
  //   const tabscroll = document.querySelector('.tabscroll') as HTMLDivElement
  //   tabscroll.scrollLeft -= e.deltaY
  // }

  const handleClick = (tab: any) => {
    onClick(tab.index)
    const element = document.getElementById(`btn-${tab.index}`) as HTMLButtonElement
    element.scrollIntoView({ block: 'center', inline: 'center', behavior: 'auto' })
  }

  useEffect(() => {
    dispatch(closePrincipalAccountDetail())
    dispatch(clearPrincipalAccountInfoDetail())
    dispatch(clearPrincipalAccountDetail())
    dispatch(clearTakeItemSearchPrincipalAccount())
    dispatch(clearSearchPrincipal())
    dispatch(clearValueSearchPrincipalName())
    dispatch(clearValueSearchPrincipalAddress())
    dispatch(clearCountSearchPrincipalAccount())
  }, [selectedTab])

  return (
    <div className={`${orientation === 'vertical' && 'vertical'} ${className}`}>
      <div className="px-20 md:px-10">
        <div className="flex justify-end pt-4 pb-1 text-body2 text-neutral-5">
          {pageNameParent && (
            <>
              <span>{pageNameParent}</span>
              <img src={iconArrowLeft} alt="Icon row" width={20} height={20} className="mx-2" />
            </>
          )}
          <span>{pageName}</span>
          <img src={iconArrowLeft} alt="Icon row" width={20} height={20} className="mx-2" />
          <span
            className={`${isOpenPrincipalAccountDetail ? 'text-neutral-5' : 'text-primary-shade2'}`}
          >
            {carrier?.carrierName || Panel?.label}
          </span>
          {isOpenPrincipalAccountDetail && (
            <>
              <img src={iconArrowLeft} alt="Icon row" width={20} height={20} className="mx-2" />
              <span className="text-primary-shade2">{principalAccountName}</span>
            </>
          )}
        </div>
        {carrier && (
          <div className="flex items-center py-3">
            <button className="p-2 rounded-full bg-neutral-7" onClick={() => navigate(goBackURL)}>
              <img src={iconArrowRight} alt="Icon arrow" />
            </button>
            <span className="pl-8 font-semibold text-neutral-1 text-headline4">
              {carrier?.carrierName}
            </span>
          </div>
        )}
        {selectedTab >= 2 &&
          needResponsive &&
          Number(windowSize.width) <= screens.lg &&
          pageName === 'Admin portal' && <ShadowTab />}
        {selectedTab <= 3 &&
          needResponsive &&
          Number(windowSize.width) <= screens.lg &&
          pageName === 'Admin portal' && <ShadowTab floatRight={true} />}
        {selectedTab >= 2 &&
          needResponsive &&
          Number(windowSize.width) <= screens.md &&
          pageName === 'System monitoring' && <ShadowTab />}
        {selectedTab <= 2 &&
          needResponsive &&
          Number(windowSize.width) <= screens.md &&
          pageName === 'System monitoring' && <ShadowTab floatRight={true} />}
        <div
          role="tablist"
          className="flex py-4 overflow-x-scroll tabscroll max-w-1024"
          // onWheel={handleOnWheel}
        >
          {tabs.map((tab) => (
            <button
              className={`px-6 mr-2 last:mr-0 py-2 text-body1 font-semibold cursor-pointer rounded-full text-center whitespace-nowrap ${
                selectedTab === tab.index ? activeTabClass : inActiveTabClass
              }`}
              onClick={() => handleClick(tab)}
              key={tab.index}
              type="button"
              role="tab"
              aria-selected={selectedTab === tab.index}
              aria-controls={`tabpanel-${tab.index}`}
              tabIndex={selectedTab === tab.index ? 0 : -1}
              id={`btn-${tab.index}`}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <hr className="border-t-neutral-5" />
      </div>
      <div role="tabpanel" aria-labelledby={`btn-${selectedTab}`} id={`tabpanel-${selectedTab}`}>
        {Panel && <Panel.Component index={selectedTab} />}
      </div>
    </div>
  )
}

export default Tabs
