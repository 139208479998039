import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import ReactLoading from 'react-loading'
import Moment from 'react-moment'
import IconDot from '../../../../assets/images/icon-dot.svg'
import ButtonBase from '../../../../components/partials/button'
import StatusBase from '../../../../components/partials/statusAction'
import { notify } from '../../../../components/toastify'
import { MESSAGES, STATUS } from '../../../../constants/messages'
import { useSession } from '../../../../helpers/checkSession'
import {
  DELETE_CONFIGURATION,
  GET_CONFIGURATION_BY_TYPE,
  UPDATE_CONFIGURATION_BY_ID,
} from '../../../../queries/adminPortal'
import { Action, Configuration, ListConfiguration } from './interface'
import RowActionConfigurationList from './dialog/rowAction'
import ChangeStatusDialog from './dialog/changeStatusDialog'
import ChangeConfigValueDialog from './dialog/changeValueDialog'
import CreateNewConfigurationDialog from './dialog/createNewConfigurationDialog'
import DeleteConfigurationDialog from './dialog/deleteConfigurationDialog'

interface Props {
  tableName: string
  type: 'AVOID_SETTING' | 'DATE_SETTING'
}

const EmailConfigurationListTable: React.FC<Props> = ({ type, tableName }) => {
  const [configurationList, setConfigurationList] = useState<Configuration[]>([])
  const [showAction, setShowAction] = useState<Action>({
    configId: null,
    isActive: false,
    lastItem: null,
  })
  const [isOpenModalChangeStatus, setIsOpenShowModalChangeStatus] = useState<boolean>(false)
  const [isOpenModalDeleteConfiguration, setIsOpenModalDeleteConfiguration] =
    useState<boolean>(false)
  const [isOpenModalChangeValueConfig, setIsOpenModalChangeValueConfig] = useState<boolean>(false)
  const isLoadingTable = false

  const [isOpenCreateNewConfiguration, setIsOpenCreateNewConfiguration] = useState<boolean>(false)
  const [currentConfiguration, setCurrentConfiguration] = useState<ListConfiguration>({
    configId: null,
    createdAt: null,
    key: null,
    value: null,
    isActive: false,
    type,
  })

  const { sessionChecking, handleErrorSession } = useSession()

  const { data: configurationByTypeData, refetch: getAllConfigurationByTypeRefetch } = useQuery(
    GET_CONFIGURATION_BY_TYPE,
    {
      fetchPolicy: 'no-cache',
      variables: {
        type,
      },
    },
  )

  const [changeStatusConfiguration, { loading: changeStatusConfigurationLoading }] = useMutation(
    UPDATE_CONFIGURATION_BY_ID,
  )

  const [deleteConfiguration, { loading: deleteConfigurationLoading }] =
    useMutation(DELETE_CONFIGURATION)

  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (configurationByTypeData && configurationByTypeData.getConfigurationByType.length > 0) {
      setConfigurationList(configurationByTypeData.getConfigurationByType)
    } else {
      setConfigurationList([])
    }
  }, [configurationByTypeData])

  useEffect(() => {
    const handleClickOutSide = (e: any) => {
      if (menuRef && !menuRef.current?.contains(e.target)) {
        setShowAction({ lastItem: showAction.configId, configId: null, isActive: false })
      }
    }
    document.addEventListener('mousedown', handleClickOutSide)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide)
    }
  }, [showAction])

  const handleUpdateInTable = async () => {
    await getAllConfigurationByTypeRefetch()
    setConfigurationList(configurationByTypeData.getConfigurationByType)
  }

  const onHandleClickAction = (item: Configuration) => {
    if (showAction.lastItem == item.configId) {
      return
    }
    setShowAction({
      configId: item.configId,
      isActive: true,
      lastItem: item.configId,
    })

    setCurrentConfiguration({
      configId: item.configId,
      isActive: item.isActive,
      key: item.key,
      value: item.value,
      type: item.type,
      createdAt: item.createdAt ? item.createdAt : null,
    })
  }

  const onHandleToggleChangeStatus = () => {
    setIsOpenShowModalChangeStatus(true)
  }

  const onHandleToggleDeleteConfiguration = async () => {
    setIsOpenModalDeleteConfiguration(true)
  }

  const handleCloseDeleteConfigurationDialog = () => {
    setIsOpenModalDeleteConfiguration(false)
  }

  // action open & close change key name dialog
  const handleOpenChangeValueConfigDialog = () => {
    setIsOpenModalChangeValueConfig(true)
  }

  const handleCloseChangeStatusDialog = () => {
    setIsOpenShowModalChangeStatus(false)
  }

  const handleCloseChangeConfigValueDialog = () => {
    setIsOpenModalChangeValueConfig(false)
  }

  const onHandleChangeStatusConfiguration = async () => {
    try {
      const response = await changeStatusConfiguration({
        variables: {
          configurationId: currentConfiguration.configId,
          data: {
            isActive: !currentConfiguration.isActive,
          },
        },
      })

      if (!changeStatusConfigurationLoading && response) {
        setIsOpenShowModalChangeStatus(false)
        handleUpdateInTable()
        if (currentConfiguration.isActive) {
          notify(MESSAGES.SUCCESS.S_CHANGE_STATUS_CONFIGURATION_INACTIVATE, STATUS.SUCCESS)
        } else {
          notify(MESSAGES.SUCCESS.S_CHANGE_STATUS_CONFIGURATION_ACTIVATE, STATUS.SUCCESS)
        }
      }
    } catch (error) {
      if (currentConfiguration.isActive) {
        handleErrorSession(error, MESSAGES.ERROR.E_CHANGE_STATUS_CONFIGURATION_INACTIVATE)
      } else {
        handleErrorSession(error, MESSAGES.ERROR.E_CHANGE_STATUS_CONFIGURATION_ACTIVATE)
      }
      setIsOpenShowModalChangeStatus(false)
    }
  }

  const onHandleDeleteConfiguration = async () => {
    if ((await sessionChecking()) === false) {
      try {
        const response = await deleteConfiguration({
          variables: {
            configId: currentConfiguration.configId,
          },
        })
        if (!deleteConfigurationLoading && response) {
          setIsOpenModalDeleteConfiguration(false)
          handleUpdateInTable()
          notify(MESSAGES.SUCCESS.S_DELETE_CONFIGURATION, STATUS.SUCCESS)
        }
      } catch (error) {
        handleErrorSession(error, MESSAGES.ERROR.E_DELETE_AP_KEY)
        setIsOpenModalDeleteConfiguration(false)
      }
    }
  }

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between pb-5">
        <p className="text-headline5A text-neutral-1 md:ml-[-40px]">{tableName}</p>
        <div>
          <ButtonBase
            className="w-[200px] md:mr-[-40px]"
            bgHover="bg-violet-900"
            onClick={() => setIsOpenCreateNewConfiguration(true)}
            // disabled={avoidList.length >= 10 ? true : false}
            bgDisabled="bg-violet-600"
          >
            Add new
          </ButtonBase>
        </div>
      </div>
      <div className="w-full top-[88px] border-2 border-neutral-6 bg-neutral-8 rounded-2xl">
        <ul className="flex bg-primary-shade4 px-6 py-5 justify-start rounded-t-[14px]">
          <li className="w-[15%]">
            <span className="text-base font-semibold font-montserrat text-neutral-2">Key name</span>
          </li>
          <li className="w-[55%]">
            <span className="text-base font-semibold font-montserrat text-neutral-2">Value</span>
          </li>
          <li className="w-[15%]">
            <span className="text-base font-semibold font-montserrat text-neutral-2">
              Added Date
            </span>
          </li>
          <li className="w-[18%] md:mr-4">
            <span className="text-base font-semibold font-montserrat text-neutral-2">Status</span>
          </li>
          <li className="w-[2%]"> </li>
        </ul>
        {isLoadingTable ? (
          <div className="flex justify-center p-5">
            <ReactLoading type="spin" color="#A989D8" height={30} width={30} />
          </div>
        ) : configurationList !== null && configurationList.length > 0 ? (
          <React.Fragment>
            <div className="mt-5 ">
              {configurationList?.map((item) => (
                <ul
                  className="flex justify-start h-auto px-6 py-5 mb-3 font-montserrat text-body2 text-neutral-3 hover:bg-neutral-7 hover:text-neutral-1 hover:h-auto"
                  key={item.configId}
                >
                  <li className="w-[15%]">
                    <p className="break-all ">{item.key}</p>
                  </li>
                  <li className="w-[55%]">
                    {type === 'DATE_SETTING' ? (
                      <div className="flex">
                        <p>
                          {item.value} <i>day(s)</i>
                        </p>
                      </div>
                    ) : (
                      item.value
                    )}
                  </li>
                  <li className="w-[15%]">
                    <Moment format="MM/DD/YYYY">{item.createdAt ?? ''}</Moment>
                  </li>
                  <li className="w-[18%]">
                    <StatusBase status={item.isActive} />
                  </li>
                  <li className="w-[2%]">
                    <button
                      className="min-w-[24px]  mb-auto"
                      onClick={() => onHandleClickAction(item)}
                    >
                      <div className="relative ">
                        <img src={IconDot} alt="Icon-Dot" className="" />
                        {showAction?.configId === item.configId && showAction.isActive && (
                          <div
                            className={`absolute right-[2px] ${
                              isOpenModalChangeStatus ? 'z-0' : 'z-10'
                            }`}
                            ref={menuRef}
                          >
                            <RowActionConfigurationList
                              status={item.isActive}
                              handleToggle={onHandleToggleChangeStatus}
                              handleDeleteConfiguration={onHandleToggleDeleteConfiguration}
                              handleChangeConfigurationValue={handleOpenChangeValueConfigDialog}
                            />
                          </div>
                        )}
                      </div>
                    </button>
                  </li>
                </ul>
              ))}
            </div>
            <ChangeStatusDialog
              modalIsOpen={isOpenModalChangeStatus}
              handleCloseDialog={handleCloseChangeStatusDialog}
              handleChangeStatusConfiguration={onHandleChangeStatusConfiguration}
              statusValue={currentConfiguration.isActive}
            />
            <DeleteConfigurationDialog
              modalIsOpen={isOpenModalDeleteConfiguration}
              handleCloseDialog={handleCloseDeleteConfigurationDialog}
              handleDeleteConfiguration={onHandleDeleteConfiguration}
            />
            {isOpenModalChangeValueConfig && (
              <ChangeConfigValueDialog
                type={type}
                configuration={{
                  configId: currentConfiguration.configId as string,
                  key: currentConfiguration.key as string,
                  value: currentConfiguration.value as string,
                  type: currentConfiguration.type,
                  isActive: currentConfiguration.isActive,
                }}
                modalIsOpen={isOpenModalChangeValueConfig}
                handleCloseDialog={handleCloseChangeConfigValueDialog}
                onHandleUpdateInTable={handleUpdateInTable}
              />
            )}
          </React.Fragment>
        ) : (
          <p className="pt-5 pb-3 text-center text-body1 text-neutral-4">No data available.</p>
        )}
      </div>
      <CreateNewConfigurationDialog
        type={type}
        modalIsOpen={isOpenCreateNewConfiguration}
        handleCloseDialog={() => setIsOpenCreateNewConfiguration(false)}
        onHandleUpdateInTable={handleUpdateInTable}
      />
    </div>
  )
}
export default EmailConfigurationListTable
