import { gql } from '@apollo/client'

export const GET_USER_PERMISSION = gql`
  query GetUserPermission ($isTemp: Boolean!){
    getUserPermission (isTemp: $isTemp) {
      mainModules {
        moduleId
        name
      }
      subModules {
        moduleId
        name
      }
      userId
    }
  }
`
