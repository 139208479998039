import SystemConfiguration from '.'
import { SUB_MODULE } from '../../constants/common'
import { checkProdSite } from '../../helpers/checkProdSite'
import { PageRoute } from '../../routes/routerInterface'

const systemConfigurationRoutes: PageRoute[] = [
  {
    path: '/system-configuration',
    element: SystemConfiguration,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
    moduleName: SUB_MODULE.SYSTEM_CONFIGURATION.name,
  },
]

export default systemConfigurationRoutes
