import UsersManagement from '.'
import { SUB_MODULE } from '../../constants/common'
import { checkProdSite } from '../../helpers/checkProdSite'
import { PageRoute } from '../../routes/routerInterface'
import UserDetail from './components/user-detail'

const usersManagementRoutes: PageRoute[] = [
  {
    path: '/users-management',
    element: UsersManagement,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
    moduleName: SUB_MODULE.USER_MANAGEMENT.name,
  },
  {
    path: '/users-management/detail',
    element: UserDetail,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
    moduleName: SUB_MODULE.USER_MANAGEMENT.name,
  },
]

export default usersManagementRoutes
