import { getDataToLocalStore, LocalStorageKey } from './localStorage'

export const getPreviewModeData = () => {
  const previewMode = getDataToLocalStore(LocalStorageKey.PREVIEW_MODE)
  const userPermissionPreviewData = getDataToLocalStore(
    LocalStorageKey.USER_PERMISSION_PREVIEW_DATA,
  )
  const userPreviewData = getDataToLocalStore(LocalStorageKey.USER_PREVIEW_DATA)

  const isPreviewMode = previewMode ? previewMode === 'true' : false

  const userPermissionPreviewInfo = userPermissionPreviewData
    ? JSON.parse(userPermissionPreviewData)
    : null

  const userPreviewInfo = userPreviewData ? JSON.parse(userPreviewData) : null

  return { isPreviewMode, userPermissionPreviewInfo, userPreviewInfo }
}
