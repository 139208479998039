import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import _isEqual from 'lodash/isEqual'
import _startCase from 'lodash/startCase'
import _uniqBy from 'lodash/uniqBy'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import iconUndo from '../../../../../assets/images/icon-undo.svg'
import iconPreview from '../../../../../assets/images/eye.svg'
import ButtonBase from '../../../../../components/partials/button'
import { notify } from '../../../../../components/toastify'
import { STATUS } from '../../../../../constants/messages'
import {
  GET_ALL_ROLE,
  GET_MODULE_BY_ROLE,
  UPDATE_USER_PERMISSION,
} from '../../../../../queries/adminPortal'
import { IOption, IPermissionOptions, IUserInformation, IUserState } from '../interfaces'
import ConfirmPreviewDialog from '../dialogs/confirmPreviewDialog'
import { LocalStorageKey, saveDataToLocalStore } from '../../../../../helpers/localStorage'

interface Props {
  userState: IUserState
  refetchGetUserInformation: () => any
}

const UserPermission: React.FunctionComponent<Props> = ({
  userState,
  refetchGetUserInformation,
}) => {
  const userInformation = userState.user
  const [currentPermissionOptions, setCurrentPermissionOptions] = useState<IPermissionOptions>()
  const [initOptions, setInitOptions] = useState<IPermissionOptions>()
  const [isDisableUndoButton, setIsDisableUndoButton] = useState<boolean>(true)
  const [roleOptions, setRoleOptions] = useState<IOption[]>([])
  const [isConfirmPreviewDialogOpen, setIsConfirmPreviewDialogOpen] = useState<boolean>(false)

  const [getModuleByRole] = useLazyQuery(GET_MODULE_BY_ROLE)

  const { data: getAllRoleData } = useQuery(GET_ALL_ROLE, { fetchPolicy: 'cache-first' })

  const [updateUserPermission] = useMutation(UPDATE_USER_PERMISSION)

  const handleSetCurrentPermissionByRole = async (userInfo: IUserInformation) => {
    const subModuleSelected: IOption[] = []
    const mainModuleSelected: IOption[] = []
    for (const subModule of userInfo.permissions) {
      mainModuleSelected.push({
        moduleId: subModule.module.parentModule.moduleId,
        label: _startCase(subModule.module.parentModule.name.toLowerCase()),
        value: subModule.module.parentModule.name,
      })

      subModuleSelected.push({
        moduleId: subModule.module.moduleId,
        label: _startCase(subModule.module.name.toLowerCase()),
        value: subModule.module.name,
        parent: subModule.module.parentModule.name,
      })
    }

    const { mainModuleOptions, subModuleOptions } = await getMainAndSubModulesByRole(
      userInfo.role.roleId,
    )

    const permissionOptions: IPermissionOptions = {
      mainModule: {
        selectedOptions: _uniqBy(mainModuleSelected, (e) => e.value),
        options: mainModuleOptions,
        childModule: {
          selectedOptions: _uniqBy(subModuleSelected, (e) => e.value),
          options: subModuleOptions,
        },
      },
      role: {
        selectedOptions: {
          label: userInfo.role.name,
          value: userInfo.role.name.toUpperCase(),
          roleId: userInfo.role.roleId,
        },
        options: roleOptions,
      },
    }

    return permissionOptions
  }

  useEffect(() => {
    handleSetCurrentPermissionByRole(userInformation).then((result) => {
      setCurrentPermissionOptions(result)
      setInitOptions(result)
    })
  }, [])

  useEffect(() => {
    if (getAllRoleData && getAllRoleData.getAllRole) {
      setRoleOptions(
        getAllRoleData.getAllRole.map((role: { name: any; roleId: any }) => {
          const opt: IOption = {
            label: role.name,
            value: role.roleId,
            roleId: role.roleId,
          }
          return opt
        }),
      )
    }
  }, [getAllRoleData])

  useEffect(() => {
    if (_isEqual(initOptions, currentPermissionOptions)) {
      setIsDisableUndoButton(true)
    } else {
      setIsDisableUndoButton(false)
    }
  }, [currentPermissionOptions])

  const getMainAndSubModulesByRole = async (roleId: string) => {
    const getModuleByRoleResponse = await getModuleByRole({
      variables: { roleId, kind: userState.user.kind },
    })
    const mainModuleOptions: IOption[] = []
    let subModuleOptions: IOption[] = []
    for (const module of getModuleByRoleResponse.data.getModuleByRoleId) {
      const mainModuleOption: IOption = {
        moduleId: module.moduleId,
        value: module.name,
        label: _startCase(module.name.toLowerCase()),
      }
      mainModuleOptions.push(mainModuleOption)
      subModuleOptions = subModuleOptions.concat(
        module.childModules.map((child: { moduleId: string; name: string }) => ({
          moduleId: child.moduleId,
          value: child.name,
          label: _startCase(child.name.toLowerCase()),
          parent: module.name,
        })),
      )
    }

    return { mainModuleOptions, subModuleOptions }
  }

  const onChangeRoleOption = async (e: any) => {
    const roleOptionSelected = e as IOption
    if (roleOptionSelected.roleId && currentPermissionOptions) {
      const { mainModuleOptions, subModuleOptions } = await getMainAndSubModulesByRole(
        roleOptionSelected.roleId,
      )

      setCurrentPermissionOptions({
        role: {
          options: roleOptions,
          selectedOptions: roleOptionSelected,
        },
        mainModule: {
          selectedOptions: [],
          options: mainModuleOptions,
          childModule: {
            selectedOptions: [],
            options: subModuleOptions,
          },
        },
      })
    }
  }

  const onChangeMainModule = async (e: any) => {
    const mainOptionSelected = e as IOption[]
    if (currentPermissionOptions) {
      const roleId = (currentPermissionOptions.role.selectedOptions as IOption).roleId

      const { subModuleOptions } = await getMainAndSubModulesByRole(roleId as string)

      let newSubModuleOptions: IOption[] = []
      for (const mainModuleSelected of mainOptionSelected) {
        const subModuleFilteredByMainModuleSelected = subModuleOptions.filter(
          (item) => item.parent === mainModuleSelected.value,
        )
        newSubModuleOptions = newSubModuleOptions.concat(subModuleFilteredByMainModuleSelected)
      }

      setCurrentPermissionOptions({
        ...currentPermissionOptions,
        mainModule: {
          ...currentPermissionOptions.mainModule,
          selectedOptions: mainOptionSelected,
          childModule: {
            options: newSubModuleOptions,
            selectedOptions: [],
          },
        },
      })
    }
  }

  const onChangeSubModule = (e: any) => {
    const option = e as IOption[]
    if (currentPermissionOptions) {
      setCurrentPermissionOptions({
        ...currentPermissionOptions,
        mainModule: {
          ...currentPermissionOptions.mainModule,
          childModule: {
            ...currentPermissionOptions.mainModule.childModule,
            selectedOptions: option,
          },
        },
      })
    }
  }

  const onClickUndoButton = () => {
    setCurrentPermissionOptions(initOptions)
  }

  const onclickPreviewButton = () => {
    setIsConfirmPreviewDialogOpen(true)
  }

  const onClickSavePermissionButton = () => {
    let newModuleIds = []
    if (currentPermissionOptions) {
      const userId = userInformation.userId
      newModuleIds = (
        currentPermissionOptions.mainModule.childModule.selectedOptions as IOption[]
      ).map((item) => item.moduleId)
      const variables = { userId, data: { newModuleIds } }

      updateUserPermission({
        variables,
        onError: () => {
          notify('Save user permission error!', STATUS.ERROR)
        },
        onCompleted: () => {
          notify('Save user permission successfully!', STATUS.SUCCESS)
          refetchGetUserInformation()
          setInitOptions(currentPermissionOptions)
          setIsDisableUndoButton(true)
        },
      })
    }
  }

  const formatRoleOptionLabel = ({ label }: IOption) => (
    <div className="flex justify-between">
      <h5>{label}</h5>
    </div>
  )

  const handleCloseConfirmPreviewDialog = (val: boolean) => {
    setIsConfirmPreviewDialogOpen(val)
    saveDataToLocalStore(LocalStorageKey.PREVIEW_MODE, false)
    saveDataToLocalStore(LocalStorageKey.USER_PERMISSION_PREVIEW_DATA, null)
    saveDataToLocalStore(LocalStorageKey.USER_PREVIEW_DATA, null)
  }

  const handlePreview = () => {
    if (!currentPermissionOptions) return
    const mainModules = (currentPermissionOptions.mainModule.selectedOptions as IOption[]).map(
      (item) => ({ moduleId: item.moduleId, name: item.value }),
    )
    const subModules = (
      currentPermissionOptions.mainModule.childModule.selectedOptions as IOption[]
    ).map((item) => ({ moduleId: item.moduleId, name: item.value }))

    saveDataToLocalStore(LocalStorageKey.USER_PREVIEW_DATA, JSON.stringify(userState.user))
    saveDataToLocalStore(
      LocalStorageKey.USER_PERMISSION_PREVIEW_DATA,
      JSON.stringify({
        mainModules,
        subModules,
        role: userState.user.role,
      }),
    )

    saveDataToLocalStore(LocalStorageKey.PREVIEW_MODE, true)
    window.open(window.location.origin)
  }

  return (
    <div>
      {roleOptions && (
        <div className="flex flex-row pt-2">
          <p className="basis-1/4">Role: </p>
          <Select
            className="basis-2/4"
            options={roleOptions}
            value={currentPermissionOptions?.role?.selectedOptions}
            onChange={onChangeRoleOption}
            formatOptionLabel={formatRoleOptionLabel}
            isDisabled
          />
        </div>
      )}
      <div className="flex flex-row pt-2">
        <p className="basis-1/4">Main Module</p>
        <Select
          isMulti
          className="basis-2/4"
          options={currentPermissionOptions?.mainModule?.options}
          value={currentPermissionOptions?.mainModule?.selectedOptions}
          onChange={onChangeMainModule}
        />
      </div>
      <div className="flex flex-row pt-2">
        <p className="basis-1/4">Sub module</p>
        <Select
          isMulti
          className="basis-2/4"
          options={currentPermissionOptions?.mainModule.childModule?.options}
          value={currentPermissionOptions?.mainModule.childModule?.selectedOptions}
          onChange={onChangeSubModule}
        />
      </div>
      <div className="flex flex-row-reverse space-x-4 space-x-reverse pt-2">
        <ButtonBase
          bgHover="bg-primary-shade1"
          className="justify-around w-[118px] text-neutral-8 bg-primary-1"
          onClick={onClickSavePermissionButton}
        >
          <p>Save</p>
        </ButtonBase>
        <ButtonBase
          bgHover="bg-primary-shade1"
          className="justify-around w-[140px] text-neutral-8 bg-primary-1"
          onClick={onclickPreviewButton}
          bgDisabled="bg-primary-shade3"
        >
          <div className="flex justify-between">
            <img src={iconPreview} alt="iconEye" width={20} height={20} />
            <p>Preview</p>
          </div>
        </ButtonBase>
        <ButtonBase
          bgHover="bg-primary-shade1"
          className="justify-around w-[118px] text-neutral-8 bg-primary-1"
          onClick={onClickUndoButton}
          bgDisabled="bg-primary-shade3"
          disabled={isDisableUndoButton}
        >
          <div className="flex justify-between">
            <img src={iconUndo} alt="iconUndo" width={20} height={20} />
            <p>Undo</p>
          </div>
        </ButtonBase>
      </div>

      <ConfirmPreviewDialog
        modalIsOpen={isConfirmPreviewDialogOpen}
        handleCloseDialog={handleCloseConfirmPreviewDialog}
        handlePreview={handlePreview}
      />
    </div>
  )
}

export default UserPermission
