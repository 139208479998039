import React, { useEffect, useState } from 'react'
import Tabs, { TabsType } from '../../components/partials/tabs'
import SystemConfiguration from '../system-configuration'
import SystemMonitoring from '../system-monitoring'
import UsersManagement from '../users-management'
import PrincipalAccountManagement from '../principal-account-management'
import EmailSystemManagement from '../email-system-management'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { IUserPermission } from '../../constants/interface'
import { SUB_MODULE } from '../../constants/common'
import { useLocation } from 'react-router-dom'
import { getDataToLocalStore, LocalStorageKey } from '../../helpers/localStorage'

// Tabs Array
const initAdminTabs: TabsType = [
  {
    label: SUB_MODULE.SYSTEM_MONITORING.displayName,
    index: 1,
    Component: SystemMonitoring,
    name: SUB_MODULE.SYSTEM_MONITORING.name,
  },
  {
    label: SUB_MODULE.USER_MANAGEMENT.displayName,
    index: 2,
    Component: UsersManagement,
    name: SUB_MODULE.USER_MANAGEMENT.name,
  },
  {
    label: SUB_MODULE.SYSTEM_CONFIGURATION.displayName,
    index: 3,
    Component: SystemConfiguration,
    name: SUB_MODULE.SYSTEM_CONFIGURATION.name,
  },
  {
    label: SUB_MODULE.PRINCIPAL_ACCOUNT_MANAGEMENT.displayName,
    index: 4,
    Component: PrincipalAccountManagement,
    name: SUB_MODULE.PRINCIPAL_ACCOUNT_MANAGEMENT.name,
  },
  {
    label: SUB_MODULE.EMAIL_SYSTEM.displayName,
    index: 5,
    Component: EmailSystemManagement,
    name: SUB_MODULE.EMAIL_SYSTEM.name,
  },
]

const AdminPortal = () => {
  const [adminTab, setAdminTab] = useState<TabsType>(initAdminTabs)
  const [selectedTab, setSelectedTab] = useState<number>()
  const location = useLocation()
  const userPermissionsState = useSelector(
    (state: RootState) => state.userReducers?.userPermissions,
  )

  let userPermissions: IUserPermission
  const userPermissionPreviewInfo = getDataToLocalStore(
    LocalStorageKey.USER_PERMISSION_PREVIEW_DATA,
  )
  const previewMode = getDataToLocalStore(LocalStorageKey.PREVIEW_MODE)

  if (previewMode === 'true' && userPermissionPreviewInfo) {
    userPermissions = JSON.parse(userPermissionPreviewInfo)
  } else {
    userPermissions = userPermissionsState
  }

  useEffect(() => {
    if (userPermissions && userPermissions.mainModules) {
      const subModules = userPermissions.subModules.map((item) => item.name)
      setAdminTab(adminTab.filter((tab) => subModules.includes(tab.name)))
    }
  }, [])

  useEffect(() => {
    if (
      location &&
      location.state &&
      (location.state as any).mainModule &&
      (location.state as any).subModule
    ) {
      const { subModule } = location.state as any
      setSelectedTab(adminTab.find((tab) => tab.name === subModule.name)?.index)
    } else {
      setSelectedTab(adminTab[0].index)
    }
  }, [adminTab])

  return (
    selectedTab && (
      <div>
        <Tabs
          needResponsive={true}
          selectedTab={selectedTab}
          pageName="Admin portal"
          onClick={setSelectedTab}
          tabs={adminTab}
        />
      </div>
    )
  )
}

export default AdminPortal
