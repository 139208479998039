import AdminPortal from './'
import { PageRoute } from '../../routes/routerInterface'
import adminPortalDetail from './containers/adminPortalDetail'
import { checkProdSite } from '../../helpers/checkProdSite'
import { MAIN_MODULE } from '../../constants/common'

const adminPortalRoutes: PageRoute[] = [
  {
    path: '/admin-portal',
    element: AdminPortal,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
    moduleName: MAIN_MODULE.ADMIN_PORTAL.name,
  },
  {
    path: '/admin-portal/detail',
    element: adminPortalDetail as any,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
    moduleName: MAIN_MODULE.ADMIN_PORTAL.name,
  },
]

export default adminPortalRoutes
