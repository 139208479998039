import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Tabs, { TabsType } from '../../components/partials/tabs'
import { KIND, ROLE, SUB_MODULE } from '../../constants/common'
import { RootState } from '../../store'
import ActivitiesManagement from '../activitiesManagement'
import DevelopmentManagement from '../developmentManagement'
import MyAgencies from '../myAgencies'
import PrincipalAccountManagement from '../principal-account-management'
import { IUserPermission } from '../../constants/interface'
import { getDataToLocalStore, LocalStorageKey } from '../../helpers/localStorage'

const DeveloperPortal = () => {
  const { userPermissions: userPermissionsState } = useSelector(
    (state: RootState) => state.userReducers,
  )
  let userPermissions: IUserPermission
  const userPermissionPreviewInfo = getDataToLocalStore(
    LocalStorageKey.USER_PERMISSION_PREVIEW_DATA,
  )
  const userPreviewInfo = getDataToLocalStore(LocalStorageKey.USER_PREVIEW_DATA)
  const previewMode = getDataToLocalStore(LocalStorageKey.PREVIEW_MODE)

  if (previewMode === 'true' && userPermissionPreviewInfo && userPreviewInfo) {
    userPermissions = JSON.parse(userPermissionPreviewInfo)
  } else {
    userPermissions = userPermissionsState
  }
  // Tabs Array
  const tabs: TabsType = [
    {
      label: SUB_MODULE.ACTIVITY_MANAGEMENT.displayName,
      index: 1,
      Component: ActivitiesManagement,
      roles: [ROLE.Admin, ROLE.Developer, ROLE.DeveloperAccountManager],
      kinds: [null, KIND.CARRIERS, KIND.AGENCIES],
      name: SUB_MODULE.ACTIVITY_MANAGEMENT.name,
    },
    {
      label: SUB_MODULE.DEVELOPMENT_MANAGEMENT.displayName,
      index: 2,
      Component: DevelopmentManagement,
      roles: [ROLE.Admin, ROLE.Developer, ROLE.DeveloperAccountManager],
      kinds: [null, KIND.CARRIERS, KIND.AGENCIES],
      name: SUB_MODULE.DEVELOPMENT_MANAGEMENT.name,
    },
    {
      label: SUB_MODULE.MY_AGENCY.displayName,
      index: 3,
      Component: MyAgencies,
      roles: [ROLE.Admin, ROLE.Developer],
      kinds: [null, KIND.AGENCIES],
      name: SUB_MODULE.MY_AGENCY.name,
    },
    {
      label: SUB_MODULE.PRINCIPAL_ACCOUNT_MANAGEMENT.displayName,
      index: 4,
      Component: PrincipalAccountManagement,
      roles: [ROLE.Admin, ROLE.AccountManager, ROLE.DeveloperAccountManager],
      kinds: [null, KIND.CARRIERS],
      name: SUB_MODULE.PRINCIPAL_ACCOUNT_MANAGEMENT.name,
    },
  ]

  const [selectedTab, setSelectedTab] = useState<number>()
  const [userTab, setUserTab] = useState<TabsType>(tabs)

  useEffect(() => {
    if (userPermissions && userPermissions.mainModules) {
      const subModules = userPermissions.subModules.map((item) => item.name)
      setUserTab(userTab.filter((tab) => subModules.includes(tab.name)))
    }
    // const list = tabs?.filter(
    //   (item) =>
    //     item.roles?.includes(userInfoState?.role?.name) &&
    //     item?.kinds?.includes(userInfoState?.kind),
    // ) as any[]
    // setSelectedTab(list[0]?.index)
  }, [])

  useEffect(() => {
    setSelectedTab(userTab[0].index)
  }, [userTab])

  return (
    selectedTab && (
      <Tabs
        selectedTab={selectedTab}
        pageName="Developer portal"
        onClick={setSelectedTab}
        tabs={userTab}
      />
    )
  )
}

export default DeveloperPortal
