// LOADING
export const SET_LOADING = 'SET_LOADING'
export const SET_UNLOADING = 'SET_UNLOADING'

// LOGIN WITH GOOGLE
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SET_USER_INFO = 'SET_USER_INFO'
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO'

// SET USER PERMISSION
export const SET_USER_PERMISSION = 'SET_USER_PERMISSION'
export const CLEAR_USER_PERMISSION = 'CLEAR_USER_PERMISSION'

// COUNT SPAMMING
export const SET_SPAMMING = 'SET_SPAMMING'
export const CLEAR_SPAMMING = 'CLEAR_SPAMMING'

//GROUP BY FILTER ACTIVITIES FOR DEV
export const ACTIVITIES_TYPE_GROUP = 'ACTIVITIES_TYPE_GROUP'
export const REQUEST_JSON_PACKET = 'REQUEST_JSON_PACKET'
export const REQUEST_FROM_DATE = 'REQUEST_FROM_DATE'
export const REQUEST_TO_DATE = 'REQUEST_TO_DATE'

//GROUP BY FILTER ACTIVITIES FOR ADMIN
export const ADMIN_ACTIVITIES_TYPE_GROUP = 'ADMIN_ACTIVITIES_TYPE_GROUP'
export const ADMIN_REQUEST_CARRIER = 'ADMIN_REQUEST_CARRIER'
export const ADMIN_REQUEST_FROM_DATE = 'ADMIN_REQUEST_FROM_DATE'
export const ADMIN_REQUEST_JSON_PACKET = 'ADMIN_REQUEST_JSON_PACKET'
export const ADMIN_REQUEST_TO_DATE = 'ADMIN_REQUEST_TO_DATE'
export const CLEAR_DATETIME_FILTER = 'CLEAR_DATETIME_FILTER'
export const ADMIN_REQUEST_AMS = 'ADMIN_REQUEST_AMS'
export const ADMIN_BY_AMS_REQUEST_FROM_DATE = 'ADMIN_BY_AMS_REQUEST_FROM_DATE'
export const ADMIN_BY_AMS_REQUEST_TO_DATE = 'ADMIN_BY_AMS_REQUEST_TO_DATE'
export const CLEAR_DATE_TIME_FILTER_BY_AMS = 'CLEAR_DATE_TIME_FILTER_BY_AMS'

// SEARCH PRINCIPAL FOR ADMIN
export const SEARCH_PRINCIPAL = 'SEARCH_PRINCIPAL'
export const CLEAR_SEARCH_PRINCIPAL = 'CLEAR_PRINCIPAL'
export const VALUE_SEARCH_PRINCIPAL_NAME = 'VALUE_SEARCH_PRINCIPAL_NAME'
export const CLEAR_VALUE_SEARCH_PRINCIPAL_NAME = 'CLEAR_VALUE_SEARCH_PRINCIPAL_NAME'
export const VALUE_SEARCH_PRINCIPAL_ADDRESS = 'VALUE_SEARCH_PRINCIPAL_ADDRESS'
export const CLEAR_VALUE_SEARCH_PRINCIPAL_ADDRESS = 'CLEAR_VALUE_SEARCH_PRINCIPAL_ADDRESS'
export const OPEN_PRINCIPAL_ACCOUNT = 'OPEN_PRINCIPAL_ACCOUNT'
export const CLOSE_PRINCIPAL_ACCOUNT = 'CLOSE_PRINCIPAL_ACCOUNT'
export const GET_PRINCIPAL_ACCOUNT_DETAIL = 'GET_PRINCIPAL_ACCOUNT_DETAIL'
export const CLEAR_PRINCIPAL_ACCOUNT_DETAIL = 'CLEAR_PRINCIPAL_ACCOUNT_DETAIL'
export const GET_PRINCIPAL_ACCOUNT_INFO_DETAIL = 'GET_PRINCIPAL_ACCOUNT_INFO_DETAIL'
export const CLEAR_PRINCIPAL_ACCOUNT_INFO_DETAIL = 'CLEAR_PRINCIPAL_ACCOUNT_INFO_DETAIL'
export const UPDATE_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT = 'UPDATE_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT'
export const CLEAR_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT = 'CLEAR_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT'
export const COUNT_SEARCH_PRINCIPAL_ACCOUNT = 'COUNT_SEARCH_PRINCIPAL_ACCOUNT'
export const CLEAR_COUNT_SEARCH_PRINCIPAL_ACCOUNT = 'CLEAR_COUNT_SEARCH_PRINCIPAL_ACCOUNT'

// CHECK TIME OUT
export const SET_IS_TIME_OUT = 'SET_IS_TIME_OUT'
