import PrincipalAccountManagement from '.'
import { PageRoute } from '../../routes/routerInterface'
import { checkProdSite } from '../../helpers/checkProdSite'
import { SUB_MODULE } from '../../constants/common'

const principalAccountManagementRouters: PageRoute[] = [
  {
    path: '/principal-account-management',
    element: PrincipalAccountManagement,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
    moduleName: SUB_MODULE.PRINCIPAL_ACCOUNT_MANAGEMENT.name,
  },
]

export default principalAccountManagementRouters
