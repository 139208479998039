import { SUB_MODULE } from '../constants/common'

export enum Role {
  Admin = 'Admin',
  Developer = 'Developer',
}

interface Permission {
  adminPortal: Role[]
  developerPortal: Role[]
}

export const permissions: Permission = {
  adminPortal: [Role.Admin],
  developerPortal: [Role.Developer],
}

export const detectRole = (permission: string, userRole: Role) => {
  if (permissions[permission as keyof Permission].includes(userRole)) {
    return true
  }
  return false
}

export const detectModule = (module: string) => {
  return Object.keys(SUB_MODULE).includes(module)
}
