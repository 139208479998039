import React, { useEffect } from 'react'
import { IUserState } from '../interfaces'
import { IKind } from '../../../../../constants/interface'
import { FormInput } from '../../../../../components/partials/formInput'
import { useForm } from 'react-hook-form'
import Select from 'react-select'

interface Props {
  userState: IUserState
}

type IRegistrationFormFields = {
  firstName: string
  lastName: string
  userType: string
  role: string
  email: string
  phoneNumber: string
}

interface IUserTypeOptions {
  label: string
  kind: IKind
}

const UserInformation: React.FunctionComponent<Props> = ({ userState }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IRegistrationFormFields>()

  const handleGetUserType = (kind: IKind) => {
    switch (kind) {
      case 'carriers':
        return 'Carrier'
      case 'agencies':
        return 'Agency management provider'
      default:
        return 'Propeller'
    }
  }

  useEffect(() => {
    if (userState && userState.user) {
      setValue('firstName', userState.user.firstName)
      setValue('lastName', userState.user.lastName)
      setValue('userType', handleGetUserType(userState.user.kind))
      setValue('role', userState.user.role.name)
      setValue('email', userState.user.email)
      setValue('phoneNumber', userState.user.phoneNumber)
    }
  }, [])

  // const formatOptionLabel = ({ label }: IUserTypeOptions) => (
  //   <div className="flex justify-between">
  //     <h5>{label}</h5>
  //   </div>
  // )

  return (
    <div className="">
      <div className="flex flex-row space-x-4 pt-2">
        <div className="basis-1/2">
          <p className="w-[246px] font-semibold text-body1 text-neutral-3">First name</p>
          <FormInput<IRegistrationFormFields>
            id="firstName"
            name="firstName"
            label="First Name"
            placeholder="Enter your first name"
            inputClassName="w-full flex-1 z-0 pr-12 text-neutral-3"
            register={register}
            errors={errors}
          />
        </div>
        <div className="basis-1/2">
          <p className="w-[246px] font-semibold text-body1 text-neutral-3">Last name</p>
          <FormInput<IRegistrationFormFields>
            id="lastName"
            name="lastName"
            label="Last Name"
            placeholder="Enter your last name"
            inputClassName="w-full flex-1 z-0 pr-12 text-neutral-3"
            register={register}
            errors={errors}
          />
        </div>
      </div>
      <div className="flex flex-row space-x-4 pt-2">
        <div className="basis-1/2">
          <p className="w-[246px] font-semibold text-body1 text-neutral-3">User type</p>
          <FormInput<IRegistrationFormFields>
            id="userType"
            name="userType"
            label="User Type"
            disabled
            inputClassName="w-full flex-1 z-0 pr-12 text-neutral-3"
            register={register}
            errors={errors}
          />
        </div>
        <div className="basis-1/2">
          <p className="w-[246px] font-semibold text-body1 text-neutral-3">Role</p>
          <FormInput<IRegistrationFormFields>
            id="role"
            name="role"
            label="Role"
            disabled
            inputClassName="w-full flex-1 z-0 pr-12 text-neutral-3"
            register={register}
            errors={errors}
          />
        </div>
        {/* <div className="basis-1/2">
          <p className="w-[246px] font-semibold text-body1 text-neutral-3">User Type</p>
          <Select
            // className="text-neutral-3"
            formatOptionLabel={formatOptionLabel}
            options={[
              {
                label: 'Agency',
                kind: 'agencies',
              },
              {
                label: 'Carrier',
                kind: 'agencies',
              },
              {
                label: 'Propeller',
                kind: null,
              },
            ]}
            value={{
              kind: userState.user.kind,
              label: handleGetUserType(userState.user.kind),
            }}
          />
        </div> */}
      </div>
      <div className="flex flex-row space-x-4 pt-2">
        <div className="basis-1/2">
          <p className="w-[246px] font-semibold text-body1 text-neutral-3">Email</p>
          <FormInput<IRegistrationFormFields>
            id="email"
            name="email"
            label="Email"
            inputClassName="w-full flex-1 z-0 pr-12 text-neutral-3"
            register={register}
            errors={errors}
          />
        </div>
        <div className="basis-1/2">
          <p className="w-[246px] font-semibold text-body1 text-neutral-3">Phone Number</p>
          <FormInput<IRegistrationFormFields>
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            inputClassName="w-full flex-1 z-0 pr-12 text-neutral-3"
            register={register}
            errors={errors}
          />
        </div>
      </div>
    </div>
  )
}

export default UserInformation
