import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation Login($tokenId: String!) {
    login(tokenId: $tokenId) {
      accessToken
      user {
        userId
        firstName
        lastName
        email
        gsuiteUsername
        gid
        kind
        avatar
        role {
          roleId
          name
        }
      }
    }
  }
`
