import ActivitiesManagement from './'
import { PageRoute } from '../../routes/routerInterface'
import { checkProdSite } from '../../helpers/checkProdSite'
import { SUB_MODULE } from '../../constants/common'

const activitiesManagementRouters: PageRoute[] = [
  {
    path: '/activities-management',
    element: ActivitiesManagement,
    isProtected: true,
    permission: checkProdSite(true, 'Developer'),
    moduleName: SUB_MODULE.ACTIVITY_MANAGEMENT.name
  },
]

export default activitiesManagementRouters
