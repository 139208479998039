import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Tabs, { TabsType } from '../../../components/partials/tabs'
import ActivitiesManagement from '../../activitiesManagement'
import CarrierInfo from '../components/carrierInfo'
import DevelopmentManagement from '../../developmentManagement'
import AgenciesListByAdmin from '../my-agencies-list/myAgencyByAdmin'
import { LocationState } from '../../../constants/interface'
import { KIND, SUB_MODULE } from '../../../constants/common'

const AdminPortalDetail = () => {
  const { state } = useLocation()
  const locationState: LocationState = state as object

  const Kind = locationState?.kind

  const tabs: TabsType = [
    {
      label: SUB_MODULE.ACTIVITY_MANAGEMENT.displayName,
      index: 1,
      Component: ActivitiesManagement,
      name: SUB_MODULE.ACTIVITY_MANAGEMENT.name,
    },
    {
      label: SUB_MODULE.DEVELOPMENT_MANAGEMENT.displayName,
      index: 2,
      Component: DevelopmentManagement,
      name: SUB_MODULE.DEVELOPMENT_MANAGEMENT.name,
    },
    {
      label: SUB_MODULE.MY_AGENCY.displayName,
      index: 3,
      Component: AgenciesListByAdmin,
      name: SUB_MODULE.MY_AGENCY.name,
    },
    {
      label: SUB_MODULE.INFORMATION.displayName,
      index: 4,
      Component: CarrierInfo,
      name: SUB_MODULE.INFORMATION.name,
    },
  ]

  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index)
  const [listTabsForAMS, setListTabsForAMS] = useState<any[]>([])

  React.useEffect(() => {
    if (Kind === KIND.CARRIERS) {
      const list = tabs?.filter((item) => item.index !== 3)
      setListTabsForAMS(list)
    }
  }, [])

  return (
    <Tabs
      pageNameParent="Admin portal"
      pageName="System monitoring"
      goBackURL="/admin-portal"
      selectedTab={selectedTab}
      carrier={location.state}
      tabs={Kind === KIND.CARRIERS ? listTabsForAMS : tabs}
      onClick={setSelectedTab}
      needResponsive={true}
    />
  )
}

export default AdminPortalDetail
