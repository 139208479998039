import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from '../store'
import { detectRole } from './roles'
import { getPreviewModeData } from '../helpers/previewMode'

/**
 * withPermission HOC
 * @method  withPermission
 * @param   {JSX.Element} [Wrapper]        - component
 * @param   {string}      [permission]     - component permission
 * @param   {boolean}     [shouldNavigate] - False: only hide this component, True: navigate to Home
 * @returns {JSX.Element}
 */

interface HOCProps {
  permission?: string
  shouldNavigate?: boolean
  moduleName?: string
}
const detectModule = (userPermissions: any, moduleName: string): boolean => {
  const { mainModules, subModules } = userPermissions
  const modules = mainModules
    .map((item: { name: string }) => item.name)
    .concat(subModules.map((item: { name: string }) => item.name))
  return modules.includes(moduleName)
}
export const HOCWithPermission = (Wrapper: any) => {
  return ({ permission = '', shouldNavigate = false, moduleName }: HOCProps) => {
    const { isPreviewMode, userPermissionPreviewInfo } = getPreviewModeData()
    let userPermissions

    if (isPreviewMode) {
      userPermissions = userPermissionPreviewInfo
    } else {
      userPermissions = useSelector((state: RootState) => state.userReducers?.userPermissions)
    }

    if (
      userPermissions?.mainModules &&
      userPermissions?.subModules &&
      moduleName
    ) {
      if (detectModule(userPermissions, moduleName)) {
        return <Wrapper />
      }

      return shouldNavigate ? <Navigate to="/" /> : null
    }
    // if (userInfoState?.role) {
    //   if (detectRole(permission, userInfoState?.role?.name)) {
    //     return <Wrapper />
    //   }
    //   return shouldNavigate ? <Navigate to="/" /> : null
    // }
    return <Wrapper />
  }
}
