import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { notify } from '../components/toastify'
import { MESSAGES, STATUS } from '../constants/messages'
import { CODE_TIMEOUT } from '../constants/statusCode'
import { LOGOUT } from '../queries/logout'
import { clearUserInfo, clearUserPermission, logout, setIsTimeOut } from '../redux/actions/users'
import { googleLogout } from '@react-oauth/google'

export const useSession = () => {
  const [logoutSystem] = useMutation(LOGOUT)
  const dispatch = useDispatch()

  // const { signOut } = useGoogleLogout({
  //   clientId: clientId,
  //   onLogoutSuccess: onLogoutSuccess,
  //   onFailure: onFailure,
  // })

  const sessionChecking = async () => {
    const lastTimeRequest = localStorage.getItem('lastTimeRequest') as string
    const currentTimeRequest = Date.parse(new Date() as any)
    if (currentTimeRequest - parseFloat(lastTimeRequest) > 2 * 60 * 60 * 1000) {
      // 2 hours to milliseconds
      notify(MESSAGES.WARNING.W_TIMEOUT_SESSION, STATUS.WARNING)
      try {
        const res = await logoutSystem({})
        if (res) {
          googleLogout()
          localStorage.clear()
          dispatch(logout())
          dispatch(clearUserInfo())
          dispatch(clearUserPermission())
          window.location.reload()
        }
      } catch (error) {
        notify(MESSAGES.ERROR.E_SOMETHING_WENT_WRONG, STATUS.ERROR)
      }
      return true
    } else {
      return false
    }
  }

  const timeOutSession = async () => {
    // await notify(MESSAGES.WARNING.W_TIMEOUT_SESSION, STATUS.WARNING)
    await setTimeout(async () => {
      googleLogout()
      localStorage.clear()
      dispatch(logout())
      dispatch(clearUserInfo())
      dispatch(clearUserPermission())
      dispatch(setIsTimeOut(false))
      window.location.reload()
    }, 1500)
  }

  const handleErrorSession = (err: object | any, messages: string) => {
    if (err?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT) {
      timeOutSession()
    } else if (err?.graphQLErrors[0]?.extensions.length > 0) {
      const errorMessage = err?.graphQLErrors[0]?.message
      notify(errorMessage, STATUS.ERROR)
    } else {
      notify(messages, STATUS.ERROR)
    }
  }

  return { sessionChecking, timeOutSession, handleErrorSession }
}
