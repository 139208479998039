import { useQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import Moment from 'react-moment'

import { MESSAGES } from '../../../../../constants/messages'
import { optionHover } from '../component/activitiesLogModel'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../../../../components/loading'
import iconFilter from '../../../../../assets/images/icon-filter.svg'
import iconFiltered from '../../../../../assets/images/icon-filtered.svg'
import IconHoverCopy from '../../../../../assets/images/icon-hover-copy.svg'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../store'
import {
  clearDateTimeFilterForDeveloper,
  setActivitiesGroupType,
  setRequestJsonPacket,
} from '../../../../../redux/actions/developerPortal'
import {
  onHandleCopyItem,
  onHandleFormatDate,
  onHandleSplitUserName,
} from '../../../../../helpers/functionUtils'
import DatePickerFilter from '../component/dateTimePicker'
import Filter, { handleGetListFilterForAdminType } from '../component/filterBase'
import { FILTER_BY_GROUP, FILTER_BY_MULTI_COLUMN } from '../../../../../queries/developerPortal'
import { styleJsonPacket } from '../../../../../constants/common'
import { useSession } from '../../../../../helpers/checkSession'
import { CODE_TIMEOUT } from '../../../../../constants/statusCode'
import {
  ActivitiesGroupModel,
  ActivitiesLogModel,
  JsonPacketGroupModel,
  RoleModel,
  SystemMonitoringFilterModel,
  SystemMonitoringHeaderModel,
  tableHeaderActivitiesMng,
} from '../admin/activitiesMngModels'

const classFilterResponsive = 'md:relative md:right-[-5px] md:ml-[-12px]'

const ActivitiesManagementDevTable = () => {
  const { timeOutSession, handleErrorSession } = useSession()
  const dispatch = useDispatch()
  const activitiesGroupType = useSelector(
    (state: RootState) => state?.developerPortalReducer?.activitiesGroupType,
  )
  const requestJsonGroupPacket = useSelector(
    (state: RootState) => state?.developerPortalReducer.requestJsonPacket,
  )
  const requestFromDate = useSelector((state: RootState) => state?.developerPortalReducer?.fromDate)
  const requestToDate = useSelector((state: RootState) => state?.developerPortalReducer?.toDate)

  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false)
  const [idSelect, setIdSelect] = useState<string>('')
  const [initialSkip] = useState<number>(0)
  const [listActivities, setListActivities] = useState<ActivitiesLogModel[]>([])
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [showActivitiesGroup, setShowActivitiesGroup] = useState<ActivitiesGroupModel[]>([])
  const [showJsonPacketGroup, setShowJsonPacketGroup] = useState<JsonPacketGroupModel[]>([])
  const [listActivitiesFilter, setListActivitiesFilter] = useState<string[]>([])
  const [listJsonPacketFilter, setListJsonPacketFilter] = useState<string[]>([])
  const [fromDate, setFromDate] = useState<string | null>('')
  const [toDate, setToDate] = useState<string | null>('')
  const [filterLoading, setFilterLoading] = useState<boolean>(false)

  const [hover, setHover] = useState<optionHover>({
    activityId: null,
    status: false,
  })

  const filterRef = useRef<HTMLDivElement>(null)
  const btnRef = useRef<HTMLDivElement>(null)

  const [listActivitiesFilterOriginal, setListActivitiesFilterOriginal] = useState<string[]>([])
  const [listJsonPacketFilterOriginal, setListJsonPacketFilterOriginal] = useState<string[]>([])

  // Get data activities log
  const {
    loading: getActivitiesLogLoading,
    error: getActivitiesLogError,
    data: getActivitiesData,
    fetchMore: fetchMoreForGetActivities,
  } = useQuery(FILTER_BY_MULTI_COLUMN, {
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        skip: initialSkip,
        take: SystemMonitoringFilterModel.DEFAULT_OFFSET,
      },
    },
  })

  // Get data group by activities type
  const { error: getGroupActivitiesTypeError, data: getGroupActivitiesTypeData } = useQuery(
    FILTER_BY_GROUP,
    {
      variables: {
        data: {
          columnName: SystemMonitoringFilterModel.TYPE_ACTIVITIES,
        },
      },
    },
  )

  // Get data group by Json Packet
  const { error: getGroupJsonPacketError, data: getGroupJsonPacketData } = useQuery(
    FILTER_BY_GROUP,
    {
      variables: {
        data: {
          columnName: SystemMonitoringFilterModel.TYPE_REQUEST_JSON_PACKET,
        },
      },
    },
  )

  // handle click outside
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (filterRef && !filterRef.current?.contains(e.target) && e.target.id !== 'icon-filter') {
        setIsOpenFilter(false)
        setIdSelect('')
        setListFilterGroupOriginal()
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isOpenFilter])

  // set list default
  const setListFilterGroupOriginal = () => {
    setListActivitiesFilter(listActivitiesFilterOriginal)
    setListJsonPacketFilter(listJsonPacketFilterOriginal)
  }

  useEffect(() => {
    setListActivities(getActivitiesData?.filterByMultiColumn)
    setShowActivitiesGroup(getGroupActivitiesTypeData?.filterByGroup)
    dispatch(setActivitiesGroupType(getGroupActivitiesTypeData?.filterByGroup))
    setShowJsonPacketGroup(getGroupJsonPacketData?.filterByGroup)
    dispatch(setRequestJsonPacket(getGroupJsonPacketData?.filterByGroup))
  }, [getActivitiesData, getGroupActivitiesTypeData, getGroupJsonPacketData])

  const handleOpenFilter = (id: string) => {
    setListFilterGroupOriginal()
    if (idSelect === id) {
      setIdSelect('')
      return
    }
    setIsOpenFilter(true)
    setIdSelect(id.toString())
  }

  // handle get list filter options passing in component Filter
  const handleGetListFilter = (field: string) => {
    switch (field) {
      case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
        return new Object({
          group: showActivitiesGroup,
          identify: SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL,
        })
      case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
        return new Object({
          group: showJsonPacketGroup,
          identify: SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL,
        })
      default:
    }
  }

  // handle save checked options when filter
  const handleListActivitiesType = (field: string) => {
    switch (field) {
      case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
        return new Object({
          listChecked: listActivitiesFilter,
          identify: SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL,
        })
      case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
        return new Object({
          listChecked: listJsonPacketFilter,
          identify: SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL,
        })
      default:
        break
    }
  }

  const onHandleClickCancel = () => {
    setListFilterGroupOriginal()
    setIsOpenFilter(false)
    setIdSelect('')
    resetGroupType()
  }

  // Reset group type, set value from store reducer
  const resetGroupType = () => {
    setShowActivitiesGroup(activitiesGroupType)
    setShowJsonPacketGroup(requestJsonGroupPacket)
  }

  // handle fetch more when scroll
  const fetchMoreData = async () => {
    try {
      const request = await fetchMoreForGetActivities({
        variables: {
          data: {
            skip: listActivities?.length,
            take: SystemMonitoringFilterModel.DEFAULT_OFFSET,
            activityType:
              listActivitiesFilter !== null && listActivitiesFilter?.length > 0
                ? listActivitiesFilter
                : null,
            jsonPacket:
              listJsonPacketFilter !== null && listJsonPacketFilter?.length > 0
                ? listJsonPacketFilter
                : null,
            fromDate: fromDate !== null && fromDate !== undefined ? fromDate : null,
            toDate: toDate !== null && toDate !== undefined ? toDate : null,
          },
        },
      })
      if (!getActivitiesLogLoading && !getActivitiesLogError) {
        const data = request.data.filterByMultiColumn
        if (!data?.length || data?.length == undefined) {
          setHasMore(false)
          return
        }
        const dataState = [...listActivities, ...data]

        setTimeout(() => {
          setListActivities(dataState)
        }, 500)
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_SHOW_ACTIVITIES_LOG)
    }
  }

  // handle copy json packet
  const onHandleCopyJsonPacket = async (jsonPacket: string) => {
    onHandleCopyItem(jsonPacket, MESSAGES.INFO.I_COPIED_JSON_PACKET, MESSAGES.ERROR.E_COPY_FAILED)
  }
  const onHandleCopyJsonReponse = async (jsonResponse: string) => {
    onHandleCopyItem(jsonResponse, MESSAGES.INFO.I_COPIED_JSON_RESPONSE, MESSAGES.ERROR.E_COPY_FAILED)
  }
  

  // handle change input in Filter
  const onInputHandler = (callBackValue: string, identify: string) => {
    //if no input the return the original
    if (identify !== null) {
      switch (identify) {
        case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
          if (activitiesGroupType !== null && activitiesGroupType?.length > 0) {
            setShowActivitiesGroup(
              activitiesGroupType.filter((item: { key: string }) => {
                return item?.key?.toLowerCase()?.includes(callBackValue.toLowerCase())
              }),
            )
          }
          break
        case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
          if (requestJsonGroupPacket !== null && requestJsonGroupPacket?.length > 0) {
            setShowJsonPacketGroup(
              requestJsonGroupPacket.filter((item: { key: string }) => {
                return item?.key?.toLowerCase()?.includes(callBackValue.toLowerCase())
              }),
            )
          }
          break
        default:
      }
    }
  }

  // handle checked value in Filter
  const onHandCheckedValue = (value: string, identify: string) => {
    if (SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL === identify) {
      if (listActivitiesFilter.includes(value)) {
        return setListActivitiesFilter(listActivitiesFilter.filter((val) => val != value))
      } else {
        return setListActivitiesFilter([...listActivitiesFilter, value])
      }
    } else {
      if (listJsonPacketFilter.includes(value)) {
        return setListJsonPacketFilter(listJsonPacketFilter.filter((val) => val != value))
      } else {
        return setListJsonPacketFilter([...listJsonPacketFilter, value])
      }
    }
  }

  // handle apply Filter
  const onHandleApplyFilter = async () => {
    setIsOpenFilter(false)
    const fromDateRequest = onHandleFormatDate(requestFromDate) as string
    const toDateRequest = onHandleFormatDate(requestToDate) as string

    setFromDate(fromDateRequest ? fromDateRequest : null)
    setToDate(toDateRequest ? toDateRequest : null)
    setFilterLoading(true)

    try {
      const request = await fetchMoreForGetActivities({
        variables: {
          data: {
            skip: initialSkip,
            take: SystemMonitoringFilterModel.DEFAULT_OFFSET,
            activityType:
              listActivitiesFilter !== null && listActivitiesFilter?.length > 0
                ? listActivitiesFilter
                : null,
            jsonPacket:
              listJsonPacketFilter !== null && listJsonPacketFilter?.length > 0
                ? listJsonPacketFilter
                : null,
            fromDate:
              fromDateRequest !== null && fromDateRequest !== undefined ? fromDateRequest : null,
            toDate: toDateRequest !== null && toDateRequest !== undefined ? toDateRequest : null,
          },
        },
      })
      if (!getActivitiesLogLoading && !getActivitiesLogError) {
        const data = request.data.filterByMultiColumn
        setListActivitiesFilterOriginal(listActivitiesFilter)
        setListJsonPacketFilterOriginal(listJsonPacketFilter)
        if (!data?.length || data === undefined) {
          setListActivities([])
          setHasMore(true)
          return
        }
        setTimeout(() => {
          setListActivities(data)
        }, 500)
      }
      setFilterLoading(false)
    } catch (error) {
      setFilterLoading(false)
      handleErrorSession(error, MESSAGES.ERROR.E_SHOW_ACTIVITIES_LOG)
    } finally {
      setFilterLoading(false)
      setIsOpenFilter(false)
      setIdSelect('')
      resetGroupType()
    }
  }

  // handle show icon filter when action
  const onHandleShowIcon = (id: string, label: string) => {
    if (isOpenFilter && id === idSelect) {
      return iconFiltered
    }

    if (
      listActivitiesFilter?.length > 0 &&
      label === SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL
    ) {
      return iconFiltered
    }

    if (
      listJsonPacketFilter?.length > 0 &&
      label === SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL
    ) {
      return iconFiltered
    }

    if (
      (fromDate !== null || toDate !== null) &&
      label === SystemMonitoringHeaderModel.TYPE_DATE_TIME_LABEL
    ) {
      return iconFiltered
    }

    return iconFilter
  }

  // Listening for update fromDate or toDate
  useEffect(() => {
    onHandleApplyFilter()
  }, [requestFromDate, requestToDate])

  // Clear date time store
  useEffect(() => {
    dispatch(clearDateTimeFilterForDeveloper())
    setToDate(null)
    setFromDate(null)
  }, [])

  // handle session timeout
  useEffect(() => {
    if (
      getGroupJsonPacketError?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT ||
      getActivitiesLogError?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT ||
      getGroupActivitiesTypeError?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT
    ) {
      timeOutSession()
    }
  }, [getGroupJsonPacketError, getActivitiesLogError, getGroupActivitiesTypeError])

  // handle responsive for request id json header at 1024px and 768px
  const handleResponsiveForRequestIDJsonDev = (label: string) => {
    if (label === SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL) {
      return 'md:!mt-0 lg:mt-[-24px]'
    }
  }

  return (
    <div className="relative overflow-auto border rounded-xl">
      <table className="w-full text-left">
        <thead className="bg-primary-shade4 text-body1 text-neutral-2 md:pr-4">
          <tr>
            {tableHeaderActivitiesMng
              .slice(1, tableHeaderActivitiesMng?.length)
              ?.map((item, idx) => (
                <th key={idx} className={`font-semibold py-5 pl-6 ${item.styles}`}>
                  <div className={`flex ${handleResponsiveForRequestIDJsonDev(item.label)}`}>
                    <span className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}>{item.name}</span>
                    <div ref={btnRef} className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}>
                      {item.isFilter && (
                        <div
                          className={`w-5 h-5 ${classFilterResponsive}`}
                          onClick={() => {
                            handleOpenFilter(idx.toString())
                          }}
                        >
                          <img
                            id="icon-filter"
                            src={onHandleShowIcon(idx.toString(), item.label)}
                            alt="Icon-Filter"
                            className="cursor-pointer mt-0.5 ml-2"
                          />
                        </div>
                      )}
                      {item.isFilter &&
                      item.label !== SystemMonitoringHeaderModel.TYPE_DATE_TIME_LABEL ? (
                        <Filter
                          isOpenFilter={isOpenFilter}
                          id={idx.toString()}
                          idSelected={idSelect}
                          objectGroupType={
                            handleGetListFilter(item.label) as handleGetListFilterForAdminType
                          }
                          handleClickCancel={onHandleClickCancel}
                          onRef={filterRef}
                          inputHandler={onInputHandler}
                          handCheckedValue={onHandCheckedValue}
                          handleFilter={onHandleApplyFilter}
                          listCheckedInGroupType={handleListActivitiesType(item.label)}
                        />
                      ) : (
                        <DatePickerFilter
                          isOpenFilter={isOpenFilter}
                          id={idx.toString()}
                          idSelected={idSelect}
                          onRef={filterRef}
                          handleClickCancel={onHandleClickCancel}
                          identifier={RoleModel.DEVELOPER}
                        />
                      )}
                    </div>
                  </div>
                </th>
              ))}
          </tr>
        </thead>
      </table>
      {filterLoading || getActivitiesLogLoading ? (
        <Loading className="relative py-6" height={30} width={30} />
      ) : (
        Boolean(listActivities !== null && listActivities?.length > 0) && (
          <InfiniteScroll
            dataLength={listActivities?.length}
            next={fetchMoreData}
            hasMore={hasMore}
            height={'max-content'}
            style={{ maxHeight: 980 }}
            loader={''}
            endMessage={''}
            className="cus-scrollbar"
          >
            <table>
              <tbody>
                {listActivities?.map((item, idx) => (
                  <tr
                    className="h-[72px] w-full hover:bg-neutral-7 pt-5"
                    key={idx}
                    onMouseEnter={() => {
                      setHover({
                        activityId: item.activityId,
                        status: true,
                      })
                    }}
                    onMouseLeave={() => {
                      setHover({
                        activityId: null,
                        status: false,
                      })
                    }}
                  >
                    <td className="w-[15%] py-5 break-all pl-6 md:pl-5 align-top">
                      <span>{item?.activityType}</span>
                    </td>
                    <td className="w-[13%] py-5 break-all pl-6 md:pl-3 align-top">
                      <span>
                        {<Moment format="yyyy-MM-DD HH:mm:ss.SSS">{item.createdAt ?? ''}</Moment>}
                      </span>
                    </td>
                    <td className="w-[22%] lg:pl-8 pl-1 md:w-[19%]">
                      <div className="relative w-full break-all ">
                        {hover?.status && item.activityId === hover.activityId && item?.jsonPacket ? (
                          <div className="h-[86px]">
                            <span
                              style={{ ...styleJsonPacket }}
                              className={`break-all lg:pl-2 pt-[19px] pl-6`}
                            >
                              {item?.jsonPacket}
                            </span>
                            <button
                              title="Copy this JSON Packet"
                              onClick={() => onHandleCopyJsonPacket(item.jsonPacket) ?? ''}
                            >
                              <img
                                src={IconHoverCopy}
                                alt="Icon-Hover-Copy"
                                className="absolute top-[24px] right-0"
                              />
                            </button>
                          </div>
                        ) : (
                          <span
                            style={{ ...styleJsonPacket }}
                            className={`break-all lg:pl-2 pt-[19px] pl-6`}
                          >
                            {item?.jsonPacket}
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="w-[15%] pr-2 py-5 pl-8 break-all align-top">
                      <span>{onHandleSplitUserName(item?.userName as string)}</span>
                    </td>
                    <td className="w-[22%] lg:pl-8 pl-1 md:w-[19%]">
                      <div className="relative w-full break-all ">
                        {hover?.status && item.activityId === hover.activityId && item?.statusResponse ? (
                          <div className="h-[86px]">
                            <span
                              style={{ ...styleJsonPacket }}
                              className={`break-all lg:pl-2 pt-[19px] pl-6`}
                            >
                              {item?.statusResponse}
                            </span>
                            <button
                              title="Copy this JSON Response"
                              onClick={() => onHandleCopyJsonReponse(item.statusResponse) ?? ''}
                            >
                              <img
                                src={IconHoverCopy}
                                alt="Icon-Hover-Copy"
                                className="absolute top-[24px] right-0"
                              />
                            </button>
                          </div>
                        ) : (
                          <span
                            style={{ ...styleJsonPacket }}
                            className={`break-all lg:pl-2 pt-[19px] pr-6`}
                          >
                            {item?.statusResponse}
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* {hasMore && (
                  <Loading className="absolute top-[100%] left-[50%]" height={30} width={30} />
                )} */}
          </InfiniteScroll>
        )
      )}
      {!listActivities?.length && !getActivitiesLogLoading && (
        <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
      )}
    </div>
  )
}

export default ActivitiesManagementDevTable
