import { SUB_MODULE } from '../../constants/common'
import { checkProdSite } from '../../helpers/checkProdSite'
import { PageRoute } from '../../routes/routerInterface'
import DevelopmentManagement from './'

const developmentManagementRouters: PageRoute[] = [
  {
    path: '/development-management',
    element: DevelopmentManagement,
    isProtected: true,
    permission: checkProdSite(true, 'Developer'),
    moduleName: SUB_MODULE.DEVELOPMENT_MANAGEMENT.name,
  },
]

export default developmentManagementRouters
