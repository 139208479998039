import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import iconTimes from '../../../../../assets/images/icon-times.svg'
import BaseDialog from '../../../../../components/dialog'
import ButtonBase from '../../../../../components/partials/button'
import DropdownBase, { Option } from '../../../../../components/partials/dropDown'
import { FormInput } from '../../../../../components/partials/formInput'
import { notify } from '../../../../../components/toastify'
import {
  ConfigurationAvoidKeyName,
  ConfigurationDateSettingKeyName,
} from '../../../../../constants/common'
import { MESSAGES, STATUS } from '../../../../../constants/messages'
import { useSession } from '../../../../../helpers/checkSession'
import { UPDATE_CONFIGURATION_BY_ID } from '../../../../../queries/adminPortal'
import { Configuration } from '../interface'
import { americanPhoneNumberPattern } from '../../../../../utils/regex'
import { FormInputPhoneNumber } from '../../../../../components/partials/formInputPhoneNumber'

type Props = {
  type: 'AVOID_SETTING' | 'DATE_SETTING'
  configuration: Configuration
  handleCloseDialog?: (val: boolean) => void
  onHandleUpdateInTable?: () => void
  modalIsOpen?: boolean
}

export type UpdateConfigurationFormFields = {
  key: string
  value: string
}

const avoidKeyNameList = ConfigurationAvoidKeyName
const dateSettingKeyNameList = ConfigurationDateSettingKeyName

const UpdateConfigurationDialog: React.FunctionComponent<Props> = ({
  type,
  configuration,
  handleCloseDialog = () => null,
  onHandleUpdateInTable = () => null,
  modalIsOpen,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm<UpdateConfigurationFormFields>({
    defaultValues: {
      value: configuration.value,
    },
  })

  const [currentConfigKeyName, setCurrentConfigKeyName] = useState(configuration.key)

  const handleDropdownOptionKeyName = (): Option[] => {
    switch (type) {
      case 'AVOID_SETTING':
        return avoidKeyNameList
      case 'DATE_SETTING':
        return dateSettingKeyNameList
      default:
        return avoidKeyNameList
    }
  }

  const handleChangeKeyName = (keyOption: Option) => {
    setValue('key', keyOption.id as string)
    setCurrentConfigKeyName(keyOption.id as string)
  }

  const [updateConfigurationById] = useMutation(UPDATE_CONFIGURATION_BY_ID)

  const { handleErrorSession } = useSession()

  const onSubmit = handleSubmit(async (data) => {
    const updateVariables = {
      data: {
        key: currentConfigKeyName,
        value: data.value,
        type,
      },
      configurationId: configuration.configId,
    }

    try {
      const dataRes = await updateConfigurationById({ variables: updateVariables })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_CHANGE_CONFIGURATION, STATUS.SUCCESS)
        onHandleUpdateInTable()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_KEY_NAME_EXISTS)
    }
    handleCloseDialog(false)
    reset()
  })

  const handleCloseCreateConfigDialog = () => {
    handleCloseDialog(false)
    reset()
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '16px',
      boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
      background: '#fdfdfd',
    },
  }

  return (
    <div>
      <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
        <div className="w-[572px] h-[450px] p-2.5 pt-0">
          <div className="flex justify-between">
            <div />
            <img
              src={iconTimes}
              alt=""
              className="cursor-pointer mr-[-12px]"
              onClick={handleCloseCreateConfigDialog}
            />
          </div>
          <p className="text-center text-headline5 mb-3 mt-[-16px]">Edit Configuration</p>
          <div className="overflow-y-scroll cus-scrollbar mr-[-23px] h-[330px]">
            <form onSubmit={onSubmit}>
              <div className="grid grid-cols-1 gap-2">
                <div className="mt-2">
                  <p className="text-hairline1 mb-2 mt-1">Configuration Type</p>
                  <div className="w-[552px]">
                    <FormInput
                      name="configType"
                      inputClassName="w-[552px] text-gray-700"
                      disabled={true}
                      value={type === 'AVOID_SETTING' ? 'Avoid' : 'Date Setting'}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-hairline1 mb-2 mt-1">Key Name</p>
                  <div className="w-[552px]">
                    <DropdownBase
                      options={handleDropdownOptionKeyName()}
                      handleOptions={handleChangeKeyName}
                      placeHolder="Choose key name"
                      isError={false}
                      className="max-h-[100px]"
                      initialValue={configuration.key}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <p className="text-hairline1 mb-2">Value</p>
                  {currentConfigKeyName === 'PHONE_NUMBER' ? (
                    <FormInputPhoneNumber<UpdateConfigurationFormFields>
                      control={control}
                      id="configValue-1"
                      type="text"
                      name="value"
                      label="Value"
                      placeholder="Enter Value"
                      className=""
                      inputClassName="w-full mt-1"
                      register={register}
                      rules={{ required: false, pattern: americanPhoneNumberPattern }}
                      errors={errors}
                    />
                  ) : (
                    <FormInput<UpdateConfigurationFormFields>
                      id="configValue-1"
                      type="text"
                      name="value"
                      label="Value"
                      placeholder="Enter Value"
                      className="mb-1"
                      inputClassName="w-[552px]"
                      register={register}
                      errors={errors}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="flex w-[636px] pb-0">
            <ButtonBase
              type="submit"
              className="w-[270px] mt-3 mr-3 justify-around border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
              onClick={handleCloseCreateConfigDialog}
              bgDisabled="bg-primary-shade3"
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              type="submit"
              className="w-[270px] mt-3 bg-primary-1 min-w-min text-center"
              onClick={onSubmit}
              bgDisabled="bg-primary-shade3"
            >
              Save
            </ButtonBase>
          </div>
        </div>
      </BaseDialog>
    </div>
  )
}

export default UpdateConfigurationDialog
