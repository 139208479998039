import ChatlioHeader from './'
import { PageRoute } from '../../routes/routerInterface'
import { checkProdSite } from '../../helpers/checkProdSite'
import { MAIN_MODULE } from '../../constants/common'

const chatlioRoutes: PageRoute[] = [
  {
    path: '/chatlio',
    element: ChatlioHeader,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
    moduleName: MAIN_MODULE.CHATLIO.name,
  },
  {
    path: '/chatlio/:id',
    element: ChatlioHeader,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
  },
]
export default chatlioRoutes
